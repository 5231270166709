<template>
    <div>
        <v-card class="mt-8 ml-4 mr-4" elevation="0">
            <v-row dense>
                <v-col cols="12" md="4">
                    <!-- Div a esquerda com as informações do contrato -->
                    <div>
                        <!-- Div com o nome do documento e código -->
                        <div>
                            <v-row dense>
                                <v-col cols="12" md="2">
                                    <v-icon dense color="#007744" size="50">mdi-file-document-multiple</v-icon>
                                </v-col>
                                <v-col cols="12" md="10">
                                    <strong>
                                        <p>{{ contrato.nome_documento }}</p>
                                    </strong>
                                    <p>{{ contrato.uuid }}</p>
                                </v-col>
                            </v-row>
                        </div>

                        <!-- Div com os as informações dos assinantes do documento -->
                        <div>
                            <fieldset class="pa-4 mb-4">
                                <legend>Assinantes do Documento</legend>

                                <div v-if="showForm">
                                    <v-row dense>
                                        <v-row dense class="d-flex">
                                            <div>
                                                <v-row>
                                                    <v-col cols="4">
                                                        <v-checkbox
                                                            v-model="inserirEmail"
                                                            label="email"
                                                            color="success"
                                                            :disabled="inserirWhats || inserirSMS"
                                                        ></v-checkbox>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-checkbox
                                                            v-model="inserirWhats"
                                                            label="Whats"
                                                            color="success"
                                                            :disabled="inserirEmail || inserirSMS"
                                                        ></v-checkbox>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-checkbox
                                                            v-model="inserirSMS"
                                                            label="SMS"
                                                            color="success"
                                                            :disabled="inserirEmail || inserirWhats"
                                                        ></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-row>
                                        <v-row dense>
                                            <v-col cols="10">
                                                <v-text-field
                                                    color="#007744"
                                                    v-model="assinanteContato"
                                                    :placeholder="placeholderInserir"
                                                    :disabled="!inserirEmail && !inserirSMS && !inserirWhats"
                                                    label="Inserir Contato:"
                                                    type="text"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn small
                                                    color="#007744"
                                                    class="white--text"
                                                    @click="adicionarEmailSignatario"
                                                    :disabled="finalizado || isCancelado"
                                                >
                                                    <v-icon>{{ icons[4].name }}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    
                                    <v-row dense>
                                        <v-col
                                            cols="12"
                                            v-for="(assinante, index) in signers"
                                            :key="index"
                                        >
                                            <v-card elevation="0" outlined tile class="mb-5">
                                                <v-card-title>
                                                    <v-row dense>
                                                        <v-col cols="2">
                                                            <v-icon class="icon-sig" color="warning" x-large>{{ icons[0].name }}</v-icon>
                                                        </v-col>
                                                        <v-col cols="10">
                                                            <p class="text-title text--primary mb-0">{{ assinante.email ? assinante.email : assinante.embed_smsnumber }}</p>
                                                            <p class="text-title text--primary mb-0">
                                                                <v-chip x-small outlined label color="warning" @click="removerSignatario(assinante.email)">Remover</v-chip>
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-chip
                                                        small
                                                        outlined
                                                        color="warning"
                                                        label
                                                        class="ml-15"
                                                        v-if="index > 0"
                                                    >
                                                        Apresentar Documento com Foto
                                                    </v-chip>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12">
                                            <v-btn small
                                                color="#529FE7"
                                                class="white--text mt-4"
                                                @click="cadastrarSignatarios"
                                                :disabled="signers.length < 2"
                                                :loading="carregando"
                                                block
                                            >
                                                <v-icon>{{ icons[3].name }}</v-icon> &nbsp;Enviar para Assinatura
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-if="isCancelado">
                                    <template>
                                        <v-card
                                            class="mx-auto text-center d-flex flex-column align-center justify-center"
                                            max-width="100%"
                                            color="error"
                                        >
                                            <v-card-text>
                                                <p class="text-h4 text-white">CANCELADO</p>
                                                <div class="text-white">Este documento está cancelado!</div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div v-if="showAssinantesDocumento">
                                    <v-row dense>
                                        <v-col cols="12" md="12">
                                            <v-card elevation="0" outlined tile class="mb-5">
                                                <v-card-title>
                                                    <v-row dense>
                                                        <v-col cols="2">
                                                            <v-icon v-if="this.contrato.status_nome === 'Finalizado'" class="icon-sig" color="#007744" x-large>{{ icons[1].name }}</v-icon>
                                                            <v-icon v-else class="icon-sig" color="#007744" x-large>{{ icons[0].name }}</v-icon>
                                                        </v-col>
                                                        <v-col cols="10">
                                                            <p class="text-title text--primary mb-0">{{ assinantes.nomeUsuario }}</p>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-title>
                                                <v-card-text>
                                                    <div>
                                                        <p class="text-content">{{ assinantes.emailUsuario }}</p>
                                                        <v-chip
                                                            small
                                                            outlined
                                                            color="#007744"
                                                            label
                                                            class="ml-15"
                                                            v-if="this.contrato.status_nome === 'Finalizado'"
                                                        >
                                                            Assinou Documento
                                                        </v-chip>
                                                    </div>                                              
                                                </v-card-text>
                                            </v-card>

                                            <v-card elevation="0" outlined tile>
                                                <v-card-title>
                                                    <v-row dense>
                                                        <v-col cols="2">
                                                            <v-icon v-if="this.contrato.status_nome === 'Finalizado'" class="icon-sig" color="#007744" x-large>{{ icons[1].name }}</v-icon>
                                                            <v-icon v-else class="icon-sig" color="#007744" x-large>{{ icons[0].name }}</v-icon>
                                                        </v-col>
                                                        <v-col cols="10">
                                                            <p class="text-title text--primary mb-0">{{ assinantes.nomeContratante }}</p>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-title>
                                                <v-card-text>
                                                    <div>
                                                        <p v-if="this.contrato.status_nome === 'Finalizado'" class="text-content text--primary">Apresentou - Documento com Foto</p>
                                                        <p class="text-content">{{ assinantes.emailContratante }}</p>
                                                        <v-btn small
                                                            color="#007744"
                                                            x-small
                                                            outlined
                                                            class="mr-3 mt-3 botao-enviar"
                                                            @click="enviarParaCliente"
                                                            v-if="this.contrato.status_nome === 'Aguardando Assinaturas'"
                                                            :loading="loading"
                                                        >
                                                            <v-icon dark> mdi-menu-up </v-icon> &nbsp;Enviar para o cliente
                                                        </v-btn>
                                                        <v-chip
                                                            small
                                                            outlined
                                                            color="#007744"
                                                            label
                                                            class="ml-15"
                                                            v-if="this.contrato.status_nome === 'Finalizado'"
                                                        >
                                                            Assinou Documento
                                                        </v-chip>
                                                    </div>                                              
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </div>
                            </fieldset>
                        </div>

                    </div>
                </v-col>

                <v-col cols="12" md="8">
                    <!-- Div a direita com o modelo do contrato gerado, fornecido pela D4 -->
                    <div class="modelo-contrato">
                        <div v-if="contrato.status_nome === 'Aguardando Signatários'"><span class="text-alert-enviar">O contrato precisa ser enviado para assinatura!</span></div>
                        <div v-else-if="contrato.status_nome === 'Aguardando Assinaturas'" id='signature-div'></div>
                        <div v-else id='embed-doc' v-html="iframeHTML" class="embed-doc"></div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import axios from "../../../../axios/service_private.js";
import loading from '../loading.vue';

export default {
  components: { loading },
    name: 'contratoModel',
    data: () => ({
        titulo: 'Modelo de Contrato',
        emailsAssinantes: null,
        assinanteInfos: {
            nomeUsuarioGerou: null,
            nomeClienteAssinou: null
        },
        assinantes: {
            nomeUsuario: null,
            emailUsuario: null,
            keySignerUsuario: null,
            nomeContratante: null,                   
            emailContratante: null,
            keySignerContratante: null
        },
        assinanteContato: null,
        assinanteEmail: null,
        assinanteNumero: null,
        uuid: null,
        name_doc: null,
        status: null,
        presencial: 0,
        icons: [
            { name: 'mdi-clock-outline', status: 'aguardando-assinatura' },
            { name: 'mdi-check-circle-outline', status: 'assinado' },
            { name: 'mdi-check', status: 'assinar' },
            { name: 'mdi-send', status: 'enviar' },
            { name: 'mdi-plus', status: 'add' }
        ],
        error: {
            assinanteEmail: null
        },
        loading: false,
        showAssinantes: false,
        loadingAssinantes: true,
        finalizado: false,
        formattedDate: null,
        isCancelado: false,
        signers: [],

        inserirEmail: false,
        inserirWhats: false,
        inserirSMS: false,
        placeholderInserir: null,

        showForm: null,
        showAssinantesDocumento: null,
        emailUser: null,
        keyUser: null,

        urlDoc: '',
        carregando: false,
        errorMessage: '',
        emailCliente: null,
        keyCliente: null,

        loading: false
    }),
    props: {
        contrato: {
            type: Object
        }
    },
    methods: {
        embedDocumento() {
            //----------INÍCIO DAS VARIAVEIS----------//
            // var key 			        = "{" + this.contrato.uuid + "}";
            var key 			        = this.contrato.uuid;
            var signer_disable_preview 	= "0";
            var signer_email 		    = this.emailUser !== null ? this.emailUser : this.assinantes.emailUsuario;
            var signer_display_name 	= ""; //Opcional
            var signer_documentation	= ""; //Opcional
            var signer_birthday 	    = ""; //Opcional
            var signer_key_signer 	    = this.keyUser !== null ? this.keyUser : this.assinantes.keySignerUsuario;
            
            var host			= "https://secure.d4sign.com.br/embed/viewblob";
            var container 		= "signature-div";
            var width 			= '100%';
            var height 			=  '100%';
            //----------FIM DAS VARIAVEIS----------//
                
            var is_safari = navigator.userAgent.indexOf('Safari') > -1;
            var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
            if ((is_chrome) && (is_safari)) {is_safari = false;}  
            if (is_safari) {
                if (!document.cookie.match(/^(.*;)?\s*fixed\s*=\s*[^;]+(.*)?$/)) {
                    document.cookie = 'fixed=fixed; expires=Tue, 19 Jan 2038 03:14:07 UTC; path=/';
                    var url = document.URL;
                    var str = window.location.search;
                    var param = str.replace("?", "");
                    if (url.indexOf("?")>-1){
                        url = url.substr(0,url.indexOf("?"));
                    }
                    window.location.replace("https://secure.d4sign.com.br/embed/safari_fix?param="+param+'&r='+url);
                }
            }

            var iframe = document.createElement("iframe");
            if (signer_key_signer === ''){
                iframe.setAttribute("src", host+'/'+key+'?email='+signer_email+'&display_name='+signer_display_name+'&documentation='+signer_documentation+'&birthday='+signer_birthday+'&disable_preview='+signer_disable_preview);
            }else{
                iframe.setAttribute("src", host+'/'+key+'?email='+signer_email+'&display_name='+signer_display_name+'&documentation='+signer_documentation+'&birthday='+signer_birthday+'&disable_preview='+signer_disable_preview+'&key_signer='+signer_key_signer);
            }

            iframe.setAttribute("id", 'd4signIframe');
            iframe.setAttribute("width", width);
            iframe.setAttribute("height", height);
            
            iframe.style.border = 0;
            iframe.setAttribute("allow", 'geolocation');
            var cont = document.getElementById(container);
            cont.appendChild(iframe);
            window.addEventListener("message", async (event) => {
                if (event.data === "signed") {
                    //alert('ASSINADO');
                    const assinado = await axios.post('api/v1/pet-primavera/contratos/documento-assinado', this.contrato.uuid);

                    if (assinado.data.status) {
                        this.$toast(assinado.data.message, {
                            type: 'success'
                        });
                    }
                }
                if (event.data === "wrong-data") {
                    alert('DADOS ERRADOS');
                }
            }, false);
        },
        async loadUrlTermo() {
            try {
                const url = await axios.get(`api/v1/pet-primavera/cliente/carregar-link-documento/${this.contrato.nome_documento}/${3}`);
                this.urlDoc = url.data.url;
            } catch(error) {
                this.$toast(error.response.data.message, {
                    type: 'error'
                });
            }
        },
        async listarAssinantes() {
            try {
                const signatarios = await axios.get('api/v1/pet-primavera/contratos/listar-signatarios', {
                    params: {
                        uuid_doc: this.contrato.uuid
                    }
                });
                
                if (signatarios && signatarios.data.status) {
                    this.loadingAssinantes = false;
                    const sig = signatarios.data.data;
                    const assinantesData = {
                        nomeUsuario: sig.nome_usuario_assinado ?? 'Não Possui Conta',
                        emailUsuario: sig.email_usuario_assinado,
                        nomeContratante: sig.nome_contratante_assinado ?? 'Não Possui Conta',                   
                        emailContratante: sig.email_contratante_assinado,
                        keySignerUsuario: sig.key_signer_usuario,
                        keySignerContratante: sig.key_signer_contratante
                    };
                    this.assinantes = assinantesData;
                    this.embedDocumento();
                }
            } catch(error) {
                this.loadingAssinantes = false;
            }
        },
        formatDate(date) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const dateObj = new Date(date);

            const day = String(dateObj.getDate()).padStart(2, '0');
            const month = months[dateObj.getMonth()];
            const year = dateObj.getFullYear();

            const time = date.split(' ')[1];

            this.formattedDate = `${day} ${month} ${year} - ${time}`;
        },
        async cadastrarSignatarios() {

            this.carregando = true;
            const data = {
                "signers" : this.signers
            };

            await axios.post('api/v1/pet-primavera/contratos/cadastrar-signatarios', {
                id_template: this.contrato.uuid,
                dados_documento: data
            }).then(async (res) => {
                if (res.data.status) {
                    const mensagem = res.data.data.message;
                    mensagem.forEach(item => {
                        if (item.status === 'created') {
                            this.$toast('Email cadastrado com sucesso!', {
                                type: 'success'
                            });
                        } else if (item.status === 'WhatsApp not activated on this account') {
                            this.$toast('Número não cadastrado! Não está ativado para esta conta!', {
                                type: 'warning'
                            });
                        }
                    });

                    const assinantes = await axios.get('api/v1/pet-primavera/contratos/obter-assinantes', {
                        params: {
                            uuid_doc: this.contrato.uuid
                        }
                    });
                    
                    if (assinantes && assinantes.data) {
                        // Montando as informações para serem enviadas para cadastro
                        let obj = {
                            uuid: this.contrato.uuid,
                            nome_usuario_assinado: assinantes.data.data[0].list[0].user_name ?? 'Não possui conta',
                            nome_contratante_assinado: assinantes.data.data[0].list[1].user_name ?? 'Não possui conta',
                            email_usuario_assinado: assinantes.data.data[0].list[0].email,
                            email_contratante_assinado: assinantes.data.data[0].list[1].email.includes('@') ? assinantes.data.data[0].list[1].email : "+" + assinantes.data.data[0].list[1].email,
                            key_signer_usuario: assinantes.data.data[0].list[0].key_signer,
                            key_signer_contratante: assinantes.data.data[0].list[1].key_signer
                        };

                        this.emailUser = assinantes.data.data[0].list[0].email;
                        this.keyUser = assinantes.data.data[0].list[0].key_signer;

                        this.emailCliente = assinantes.data.data[0].list[1].email;
                        this.keyCliente = assinantes.data.data[0].list[1].key_signer;

                        await axios.post('api/v1/pet-primavera/contratos/registrar-assinantes', {
                            assinantes: obj
                        }).then(async (res) => {
                            this.carregando = false;
                            this.contrato.status_nome = 'Aguardando Assinaturas';
                            this.$toast(res.data.message, {
                                type: 'success'
                            });
                            await this.listarAssinantes();
                            await this.enviarParaAssinatura();
                        }).catch((error) => {
                            this.carregando = false;
                            if (error.response.data && !error.response.data.status) {
                                this.$toast(error.response.data.message, {
                                    type: 'error'
                                });
                            } else {
                                this.$toast('Erro ao cadastrar assinantes em nossa base de dados, entre em contato com o suporte!', {
                                    type: 'error'
                                });
                            }
                        });
                    } else {
                        this.carregando = false;
                        return this.$toast('Erro ao buscar assinantes, contate o suporte!', {
                            type: 'success'
                        });
                    }
                }

            }).catch((error) => {
                this.carregando = false;
                if (error.response.data && error.response.data.status) {
                    this.$toast(error.response.data.data.message, {
                        type: 'error'
                    });
                } else {
                    this.$toast('Erro ao cadastrar assinantes, por favor, tente novamente!', {
                        type: 'error'
                    });
                }
            });
        },
        async enviarParaAssinatura() {

            await axios.post('api/v1/pet-primavera/contratos/enviar-para-assinatura', {
                uuid_document: this.contrato.uuid,
                skip_email: this.presencial,
                workflow: 1
            }).then(async (res) => {
                this.enviando = false;
                if (res.data.status) {
                    this.assinantesDoc();
                    this.embedDocumento();
                } else {
                    this.$toast(res.data.data.mensagem_pt, {
                        type: 'warning'
                    });
                }
            }).catch((error) => {
                this.enviando = false;
                if (error.response.data && error.response.data.status) {
                    this.$toast(error.response.data.data.mensagem_pt, {
                        type: 'error'
                    });
                } else {
                    this.$toast(error.response.data.data.mensagem_pt, {
                        type: 'error'
                    });
                }
            });
        },
        async enviarParaCliente() {
            await this.reenviarParaAssinatura();
        },
        async reenviarParaAssinatura(){

            this.loading = true;
            await axios.post('api/v1/pet-primavera/contratos/reenviar-para-assinatura', {
                uuid_document: this.contrato.uuid,
                email: this.emailCliente ? this.emailCliente : this.assinantes.emailContratante,
                key_signer: this.keyCliente ? this.keyCliente : this.assinantes.keySignerContratante
            }).then(async (res) => {
                this.loading = false;
                if (res.data.status) {
                    this.$toast("Mensagem enviada!", {
                        type: 'success'
                    });
                } else {
                    this.$toast(res.data.data.message, {
                        type: 'error'
                    });
                }
            }).catch((error) => {
                this.loading = false;
                if (error.response.data && error.response.data.status) {
                    this.$toast(error.response.data.data.mensagem_pt, {
                        type: 'error'
                    });
                } else {
                    this.$toast(error.response.data.data.message, {
                        type: 'error'
                    });
                }
            });
        },
        adicionarEmailSignatario() {
            const emailRegex = /.+@.+\..+/;
            const telefoneRegex = /^\+?[1-9]\d{1,14}$/;
            let metodoAssinatura = this.inserirWhats ? 'whats' : 'sms';

            if (emailRegex.test(this.assinanteContato)) {
                let newSigner = {
                    email: this.assinanteContato,
                    act: "1",
                    foreign: "0",
                    certificadoicpbr: "0",
                    assinatura_presencial: "0",
                    docauth: this.signers.length > 0 ? "1" : "0",
                    embed_methodauth: 'email'
                };

                this.signers.push(newSigner);
                this.assinanteContato = null;
                this.inserirEmail = false;
                this.inserirWhats = false;
                this.inserirSMS = false;
            } else if (telefoneRegex.test(this.assinanteContato)) {
                let newSigner = {
                    skipemail: "1",
                    act: "1",
                    foreign: "0",
                    certificadoicpbr: "0",
                    assinatura_presencial: "0",
                    docauth: "1",
                    embed_methodauth: metodoAssinatura,
                    embed_smsnumber: this.assinanteContato,
                    whatsapp_number: this.assinanteContato
                };
                
                this.signers.push(newSigner);
                this.assinanteContato = null;
                this.inserirEmail = false;
                this.inserirWhats = false;
                this.inserirSMS = false;
                
            } else {
                this.$toast('Por favor, verifique as informações de email e telefone!', {
                    type: 'error'
                });
            }
        },
        assinantesDoc() {
            if (this.contrato.status_nome === 'Aguardando Signatários') {
                this.showAssinantes = false;
                this.loadingAssinantes = false;
                this.isCancelado = false;
                this.finalizado = false;
                this.showForm = true;
            }else if (this.contrato.status_nome === 'Aguardando Assinaturas') {
                this.showAssinantes = true;
                this.loadingAssinantes = true;
                this.isCancelado = false;
                this.finalizado = false;
                this.showForm = false;
                this.showAssinantesDocumento = true;
            } else if (this.contrato.status_nome === 'Cancelado') {
                this.showAssinantes = false;
                this.loadingAssinantes = false;
                this.isCancelado = true;
                this.finalizado = false;
                this.showForm = false;
            } else {
                this.showAssinantes = true;
                this.loadingAssinantes = true;
                this.finalizado = true;
                this.isCancelado = false;
                this.showForm = false;
                this.showAssinantesDocumento = true;
            }
        },
        isNumber(assinante) {
            const telefoneRegex = /^\+?[1-9]\d{1,14}$/;
            return telefoneRegex.test(assinante);
        },
        removerSignatario(email) {
           this.signers = this.signers.filter(signer => signer.email !== email);
           this.$toast('Email removido com sucesso!', {
            type: 'success'
           });
        }
    },
    watch: {
        loading (val) {
            if (!val) return
            //setTimeout(() => (this.loading = false), 2000)
        },

        inserirEmail(value) {
            if (value) this.placeholderInserir = 'exemplo@gmail.com';
        },

        inserirWhats(value) {
            if (value) this.placeholderInserir = '+5567911223344';
        },

        inserirSMS(value) {
            if (value) this.placeholderInserir = '+5567911223344';
            
        }
    },
    computed: {
        iframeHTML() {
            if (this.contrato.status_nome === 'Finalizado') {
                return `<iframe src="${this.urlDoc}" width="100%" height="100%" frameborder="0" style="border: 0;"></iframe>`;
            }
            return '';
        }
    },
    async mounted() {
        this.assinantesDoc();
        if (this.contrato.status_nome !== 'Cancelado' && this.contrato.status_nome !== 'Aguardando Signatários') await this.listarAssinantes();

        if (this.contrato.status_nome === 'Finalizado') await this.loadUrlTermo();
    }
};
</script>

<style scoped>

.info-contrato {
    border: 1px solid black;
}

.modelo-contrato {
    border: 1px solid black;
}

.outlined-chip {
    border-color: #529FE7 !important;
    color: #529FE7 !important;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 0;
}

.outlined-chip span {
    display: block;
}

#signature-div {
  width: 100%;
  height: 100vh; /* Ajuste conforme necessário para garantir que a div ocupe a altura desejada */
  display: flex;
  justify-content: center;
  align-items: center;
}

#iframe {
  width: 100%;
  height: 100%;
  border: 0; /* Remove a borda do iframe */
}

.text-title {
    padding-top: 10px;
    font-size: .8em;
    font-weight: bolder;
}

.text-content {
    font-size: .9em;
    padding-left: 60px;
}

.icon-sig {
    margin: 7px 5px;
}

.loading-size {
    width: 150px;
}

.isCancelado {
    font-size: 1em;
}

.modelo-contrato {
    width: 100%;
    height: 100vh; /* 100% da altura da visualização */
    display: flex;
    justify-content: center;
    align-items: center;
}

#embed-doc {
    width: 100%;
    height: 100%;
    overflow: hidden; /* Para evitar barras de rolagem */
}

#embed-doc iframe {
    width: 100%;
    height: 100%;
}

.text-alert-enviar {
    font-size: 1.5em;
    font-weight: bolder;
}

.text-white {
    color: white;
}

.botao-enviar {
    margin-left: 58px;
}

</style>