<template>
    <div>
        <v-card>
            <v-card-title class="mx-auto">
                <strong class="titulo-stepper">{{ titulo }}</strong>
            </v-card-title>

            <v-divider></v-divider>

            <v-row dense>
                <v-expansion-panels accordion flat>
                    <v-expansion-panel v-for="(pet, index) in pets" :key="index">
                        <v-expansion-panel-header
                            color="green lighten-1"
                            disable-icon-rotate
                        >
                            <strong>Pet {{ index + 1 }}</strong>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-form
                                ref="form"
                                class="pa-8 mx-auto"
                            >
                                <fieldset class="pa-4 mb-4">
                                    <legend>Dados do Pet {{ index + 1 }}:</legend>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <strong>
                                                <v-checkbox
                                                    class="ml-5 mt-1"
                                                    color="success"
                                                    label="É um adicional?"
                                                    v-model="pet.is_adicional"
                                                ></v-checkbox>
                                            </strong>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-chip
                                                class="ml-3 mb-6"
                                                close-icon="mdi-close-outline"
                                                :color="!removendo ? '#007744' : 'error'"
                                                link
                                                outlined
                                                @click="removerDependente(pet.nome_pet)"
                                            >
                                                {{ !removendo ? remover : 'Removendo...' }}
                                            </v-chip>
                                        </v-col>

                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.nome_pet)">
                                            <v-text-field
                                                color="#007744"
                                                v-model="pet.nome_pet"
                                                :error-messages="error.nome_pet"
                                                label="Nome do PET:"
                                                type="text"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.cor_pet)">
                                            <v-text-field
                                                color="#007744"
                                                v-model="pet.cor_pet"
                                                :error-messages="error.cor"
                                                label="Cor do PET:"
                                                type="text"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.data_nasc_pet)">
                                            <v-text-field
                                                color="#007744"
                                                v-model="pet.data_nasc_pet"
                                                :error-messages="error.data_nasc_pet"
                                                label="Data de nascimento do PET:"
                                                type="date"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.data_obito_pet)">
                                            <v-text-field
                                                color="#007744"
                                                v-model="pet.data_obito_pet"
                                                :error-messages="error.data_obito_pet"
                                                label="Data de óbito do PET:"
                                                type="date"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.modalidade_cremacao)">
                                            <v-autocomplete
                                                v-model="pet.modalidade_cremacao"
                                                item-value="value"
                                                item-text="modalidade"
                                                label="Modadalidade de Cremação:"
                                                color="#007744"
                                                :items="modalidade_cremacao"
                                                auto-select-first
                                                outlined
                                                dense
                                                chips
                                                clearable
                                                deletable-chips
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.especie_pet)">
                                            <v-autocomplete
                                                v-model="pet.especie"
                                                item-value="nome"
                                                item-text="nome"
                                                label="Espécie:"
                                                color="#007744"
                                                :items="especies"
                                                auto-select-first
                                                outlined
                                                dense
                                                chips
                                                clearable
                                                deletable-chips
                                                @input="defineRacas"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.raca_pet)">
                                            <v-autocomplete
                                                item-value="nome"
                                                item-text="nome"
                                                label="Raça:"
                                                color="#007744"
                                                v-model="pet.raca"
                                                :items="racas"
                                                :disabled="racas.length <= 0"
                                                auto-select-first
                                                outlined
                                                dense
                                                chips
                                                clearable
                                                deletable-chips
                                                @input="(value) => defineRacaPet(value)"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.peso_pet)">
                                            <v-text-field
                                                color="#007744"
                                                v-model="pet.peso"
                                                :error-messages="error.peso"
                                                label="Peso em KG do PET:"
                                                type="number"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.altura_pet)">
                                            <v-text-field
                                                color="#007744"
                                                v-model="pet.altura"
                                                :error-messages="error.altura"
                                                label="Altura em Centimetros do PET:"
                                                type="number"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="6" v-if="tipoContrato(campos.porte_pet)">
                                            <v-autocomplete
                                                v-model="pet.porte"
                                                item-value="nome"
                                                item-text="nome"
                                                label="Porte:"
                                                color="#007744"
                                                :items="portes"
                                                auto-select-first
                                                outlined
                                                dense
                                                chips
                                                clearable
                                                deletable-chips
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </fieldset>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row dense v-if="titulo !== 'Contrato PET Primavera - Particular'">
                    <v-col>
                        <v-btn small
                            @click="addPet"
                            :disabled="titulo === 'Contrato PET Primavera - Particular - Multiplos pets' && pets.length === 4"
                            class="mt-4 mb-4 ml-4 white--text"
                            color="#007744"
                            fab
                            small
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <span class="add-pet">Adicionar PET</span>
                    </v-col>
                </v-row>
            </v-row>  
        </v-card>
    </div>
</template>

<script>
import axios from '../../../../axios/service_private'

export default {
    name: 'pets',
    data: () => ({
        pets: [],
        remover: 'Remover Dependente',
        pet: {
            id: null,
            id_titular: null,
            contratante_id: null,
            is_adicional: false,
            nome_pet: null,
            cor_pet: null,
            data_nasc_pet: null,
            data_obito_pet: null,
            modalidade_cremacao: 0,
            especie: null,
            porte: null,
            raca: null,
            peso: null,
            altura: null,           
            raca_id: null
        },
        error: {
            nome_pet: null,
            cor_pet: null,
            data_nasc_pet: null,
            data_obito_pet: null,
            modalidade_cremacao: 0,
            especie: null,
            raca: null,
            peso: null,
            altura: null,
            porte: null
        },
        modalidade_cremacao: [
            {modalidade: 'Sem resgate de cinzas', value: 0},
            {modalidade: 'Com resgate de cinzas', value: 1}
        ],
        portes: [
            {id: 0, nome: "P"},
            {id: 1, nome: "M"},
            {id: 2, nome: "G"},
            {id: 3, nome: "GG"}
        ],
        racas: [],
        especies: [],
        racaId: [],
        removendo: false
    }),
    props: {
        campos: {
            type: Object
        },
        titulo: {
            type: String
        },
        ordem: {
            type: Number
        },
        infoCliente: {
            type: Object
        }
    },
    methods: {
        addPet() {
            this.pets.push({
                is_adicional: false,
                nome_pet: "",
                cor_pet: "",
                data_nasc_pet: null,
                data_obito_pet: null,
                modalidade_cremacao: 0, // 0 - Sem resgate | 1 - Com Resgate
                especie: "",
                raca: "",
                peso: "",
                porte: "",
                altura: "",
                raca_id: null,
            });
        },
        async preencherCampos() {
            await this.getEspecies();
            await this.getRacas();
            
            if (this.infoCliente.pets.length > 0 && this.infoCliente.pets[0].is_adicional_pet !== undefined) {
                
                this.infoCliente.pets.forEach(pet => {
                    let especiePet = this.especies.find(especie => especie.nome.trim() === pet.especie_pet.trim());
                    let racaPet = this.racas.find(raca => raca.nome.trim() === pet.raca_pet.trim());

                    let petAdd = {
                        id: pet.id_pet_table,
                        is_adicional: pet.is_adicional_pet,
                        nome_pet: pet.nome_pet,
                        cor_pet: pet.cor_pet,
                        data_nasc_pet: pet.data_nasc_pet,
                        data_obito_pet: pet.data_obito_pet,
                        modalidade_cremacao: pet.modalidade_cremacao_pet,
                        especie: especiePet ? especiePet.nome : '',
                        raca: racaPet ? racaPet.nome : '',
                        peso: pet.peso_pet,
                        altura: pet.altura_pet,
                        porte: pet.porte_pet,
                        //raca_id: pet.raca_id
                        raca_id: racaPet.id
                    };
                    this.pets.push(petAdd);
                });
            } else {
                
                this.infoCliente.pets.forEach(pet => {
                    let especiePet = this.especies.find(especie => especie.nome === 'CANINA');
                    let racaPet = this.racas.find(raca => raca.id === pet.raca_id);
                    
                    let petAdd = {
                        id: pet.id,
                        id_titular: pet.titular_id_pet,
                        is_adicional: pet.is_adicional,
                        nome_pet: pet.nome_pet,
                        cor_pet: pet.cor_pet,
                        data_nasc_pet: pet.data_nascimento_pet,
                        data_obito_pet: pet.data_obito_pet,
                        modalidade_cremacao: pet.is_resgate ? 1 : 0,
                        especie: especiePet ? especiePet.nome : '',
                        raca: racaPet ? racaPet.nome : '',
                        peso: pet.peso_pet,
                        altura: pet.altura_pet,
                        porte: pet.porte_pet,
                        raca_id: racaPet.id
                    };
                    this.pets.push(petAdd);
                });
            }
        },
        removerDependente(nome) {
            setTimeout(() => {
                this.removendo = !this.removendo;
                this.pets = this.pets.filter(pet => pet.nome_pet !== nome);
            }, 500);
            this.removendo = !this.removendo;
        },
        emitDados() {
            this.converte();
            this.$emit('dadosPets', this.pets);
        },
        converte() {
            this.pets = this.pets.map((pet, index) => ({
                ...pet,
                //contratante_id: this.infoCliente && this.infoCliente.pets.length > 0 ? this.infoCliente.pets[0].contratante_id_pet : this.infoCliente.id_contratante_table ? this.infoCliente.id_contratante_table : null,
                contratante_id: this.infoCliente && this.infoCliente.id_contratante_table ? this.infoCliente.id_contratante_table : null,
                raca: pet.raca,
                peso: parseFloat(pet.peso),
                altura: parseFloat(pet.altura),
                raca_id: Number(this.racaId[index] !== undefined ? this.racaId[index] : pet.raca_id)
            }));
            return this.pets;
        },
        tipoContrato(array) {
            return array.includes(this.ordem);
        },
        async getRacas() {
            await axios.get("api/v1/pet-primavera/racas-sem-pagination")
                .then((response) => {
                    if (response.data.status) {
                        this.racas = response.data.racas;
                    }
                });
        },
        async getEspecies() {
            await axios.get("api/v1/pet-primavera/especies-sem-pagination")
                .then((response) => {
                    if (response.data.status) {
                        this.especies = response.data.especies;
                    }
                });
        },
        defineRacas() {
            this.getRacas();
        },
        defineRacaPet(value) {
            this.racas.forEach(raca => {
                if (raca.nome === value) {
                    this.racaId.push(raca.id);
                }
            });
        },
        racaDefinir(id) {
            this.racas.forEach(raca => {
                if (raca.id === id) return raca.descricao;
            });
        },
        async setup() {
            await this.getEspecies();
        }
    },
    watch: {
        infoCliente(value) {
            if (value !== null && value !== undefined) this.preencherCampos();
        }
    },
    mounted() {
        this.setup();
        this.addPet();
    }
};
</script>

<style scoped>
.buttom-steps {
    margin: 10px 10px;
}

.titulo-stepper {
    color: #007744;
    margin: auto;
}

.add-pet {
    font-weight: bolder;
    font-size: 1em;
    margin: 8px 12px;
}

</style>
