<template>
  <v-card class="mb-1" elevation="0">
    <v-card-title>
      <strong class="titulo-header-page">Boletos Gerados</strong>
      <v-spacer></v-spacer>
    </v-card-title>
    <div>
      <v-row dense>
        <v-col cols="12" md="12">
          <div class="pl-5 pr-5">
            <v-row dense>
              <v-col cols="12" md="3">
                <v-text-field
                  color="#007744"
                  label="Nome Titular:"
                  v-model="nomeTitular"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="3">
                <v-text-field
                  color="#007744"
                  label="Data Inicial:"
                  type="date"
                  v-model="datas.dataIni"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="3">
                <v-text-field
                  color="#007744"
                  label="Data Final:"
                  type="date"
                  v-model="datas.dataFim"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <loading v-if="carregamento" />
    <found v-else-if="!carregamento && data.length == 0" />
    <v-simple-table v-else dense fixed-header height="400px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Titular</th>
            <th class="text-left">Descrição</th>
            <th class="text-left">Contato</th> 
            <th class="text-center">Data de Vencimento</th>
            <th class="text-left">Situação</th>
            <th class="text-left">Visualizar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in titulares" :key="index">
            <td class="text-left">{{ item.titular }}</td>
            <td class="text-left">{{ item.observacao }}</td>
            <td class="text-left">{{ item.contato }}</td> 
            <td class="text-center">{{ item.data_vencimento }}</td>
            <td class="text-left">
              <v-chip
                v-if="item.situacao === 'Em Aberto'"
                color="grey"
                outlined
                x-small
              >
                {{ item.situacao }}
              </v-chip>
              <v-chip v-else color="green" outlined x-small>
                {{ item.situacao }}
              </v-chip>
            </td>
            <td class="text-left">
              <v-btn
                small
                v-if="item.situacao === 'Em Aberto'"
                color="#007744"
                class="white--text"
                x-small
                @click="visualizarCobranca(item.visualizar)"
              >
                <v-icon class="text--center">mdi-eye</v-icon>
              </v-btn>
              <v-btn
                small
                v-else
                color="#007744"
                class="white--text"
                x-small
                disabled
              >
                <v-icon class="text--center">mdi-eye-off</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="totais" v-if="data && data.length > 0">
      <ul>
        <li>Quantidade de cobranças: {{ titulares.length }}</li>
        <li>Cobranças em Aberto: {{ emAberto }}</li>
        <li>Cobranças Quitadas: {{ quitados }}</li>
      </ul>
    </div>
  </v-card>
</template>

<script>
import axios from "../../axios/service_private";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data: () => ({
    data: [],
    titulares: [],
    titular: {
      nome: null,
      link: null,
      contato: null,
      data_criacao: null,
    },
    carregamento: false,
    datas: {
      dataIni: moment().startOf("month").format("yyyy-MM-DD"),
      dataFim: moment().endOf("month").format("yyyy-MM-DD")
    },
    quitados: 0,
    emAberto: 0,
    nomeTitular: "",
  }),
  methods: {
    async getTitulares() {
      this.carregamento = true;
      try {
        const titulares = await axios.get(`api/v1/pet-primavera/relatorio/cobrancas`, {
          params: { ...this.datas },
        });

        if (titulares.data.status) {
          this.carregamento = false;
          this.data = titulares.data.cobrancas;
          this.titulares = this.data;
          this.situacoes(this.titulares);
        }
      } catch (error) {
        this.carregamento = false;
        this.$toast(error.response.data.message, {
          type: "error",
        });
      }
    },
    situacoes(array) {
      array.forEach((item) => {
        if (item.situacao === "Quitado") {
          this.quitados += 1;
        } else if (item.situacao === "Em Aberto") {
          this.emAberto += 1;
        }
      });
    },
    formatDate() {
      const dataInicial = moment(this.datas.dataIni).format("YYYY-MM-DD");
      const dataFinal = moment(this.datas.dataFim).format("YYYY-MM-DD");
      return [dataInicial, dataFinal];
    },
    visualizarCobranca(link) {
      if (link === "Link não encontrado") {
        this.$toast(link, {
          type: "error",
        });
      } else {
        window.open(link, "_blank");
      }
    },
    async setup() {
      this.$store.dispatch("verifyToken");
      await this.getTitulares();
    },
  },
  watch: {
    async "datas.dataIni"() {
      this.quitados = 0;
      this.emAberto = 0;
      await this.getTitulares();
    },
    async "datas.dataFim"() {
      this.quitados = 0;
      this.emAberto = 0;
      await this.getTitulares();
    },
    nomeTitular(novoValor) {
      if (novoValor === "") {
        this.titulares = this.data;
      } else {
        this.titulares = this.data.filter((titular) =>
          titular.titular.toLowerCase().includes(novoValor.toLowerCase())
        );
      }
    },
  },
  async mounted() {
    await this.setup();
  },
};
</script>

<style scoped>
.totais {
  padding: 20px 0px; 
}

.totais ul {
  list-style: none;
}

.totais li {
  font-weight: bold;
  padding-top: 5px;
}

</style>
