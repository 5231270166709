<template>
  <div>
    <!-- Load de geração de contrato -->
    <div v-if="showLoader" class="d-flex align-center justify-center ma-8">
      <v-progress-circular
        :size="50"
        class="margin"
        color="#046c34"
        indeterminate
      ></v-progress-circular>

      <p style="margin-left: 10px; color: #046c34">Gerando documento..</p>
    </div>

    <!-- Modal para digitar o nome do Pet a ser excluído -->
    <v-dialog v-model="dialogExcluirDependente" max-width="600px" persistent>
      <v-card>
        <v-card-title><b>Excluir Pet(s) do Plano!</b></v-card-title>
        <v-divider></v-divider>
        <v-list flat>
          <v-list-item>
            <v-row dense>
              <v-col cols="12">
                <v-list-item-content>
                  <div class="d-flex space">
                    <v-row dense>
                      <v-col cols="10">
                        <v-text-field
                          class="mt-3 mr-3"
                          color="#007744"
                          label="Nome Pet:"
                          type="text"
                          v-model="nomePetParaExcluir"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-btn
                          small
                          color="#007744"
                          class="mr-3 mt-3 white--text"
                          @click="addPetExcluir"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <div v-if="nomesPetsExcluir.length > 0">
                    <v-chip
                      label
                      color="warning"
                      outlined
                      v-for="(nomePet, index) in nomesPetsExcluir"
                      :key="index"
                      class="ml-1 mr-5 mb-5"
                    >
                      <span>{{ nomePet }}</span>
                    </v-chip>
                  </div>
                  <div class="d-flex">
                    <v-btn
                      small
                      color="#007744"
                      class="ml-1 mr-4 mt-1 white--text"
                      @click="excluirDependente(nomesPetsExcluir)"
                      :disabled="nomesPetsExcluir.length < 1"
                    >
                      <v-icon dark> mdi-delete </v-icon>
                      Excluir
                    </v-btn>
                    <v-btn
                      small
                      color="error"
                      class="ml-1 mr-4 mt-1"
                      @click="fecharAbrirModalExcluirDependente"
                    >
                      <v-icon dark> mdi-close </v-icon>
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- Modal com o modelo do contrato gerado -->
    <v-dialog
      v-model="dialogExibirContrato"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card elevation="0">
        <div class="d-flex align-center auto">
          <v-toolbar dense dark color="#007744">
            <v-btn small icon @click="fecharModalExibirContrato">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ contrato.tipo_contrato }}</v-toolbar-title>
          </v-toolbar>
        </div>

        <template>
          <contratoModel v-if="dialogExibirContrato" :contrato="contrato" />
        </template>
      </v-card>
    </v-dialog>

    <!-- Dialog com os formulários para geração de um novo contrato -->
    <v-dialog
      v-model="dialogGerarContrato"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <div class="d-flex align-center auto">
          <v-toolbar dark color="#007744">
            <v-btn small dense icon @click="fecharModalContrato">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Gerar Novo Contrato</v-toolbar-title>
          </v-toolbar>
        </div>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
              color="#007744"
              editable
            >
              Informações do Contratante
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
              color="#007744"
              editable
            >
              Endereço do Contratante
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              v-if="!['cancelamento_particular'].includes(item.value)"
              :complete="e1 > 3"
              step="3"
              color="#007744"
              editable
            >
              PET's
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              v-if="
                ['inclusao_associado', 'contrato_adesao'].includes(item.value)
              "
              :complete="e1 > 4"
              step="4"
              color="#007744"
              editable
            >
              Adesão
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              v-if="
                ['inclusao_associado', 'contrato_adesao'].includes(item.value)
              "
              :complete="e1 > 5"
              step="5"
              color="#007744"
              editable
            >
              Mensalidade
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              v-if="
                [
                  'cremacao_particular',
                  'cremacao_particular_multiplos',
                  'contrato_adesao',
                ].includes(item.value)
              "
              :complete="e1 > 6"
              step="6"
              color="#007744"
              editable
            >
              Forma de Pagamento
            </v-stepper-step>
          </v-stepper-header>

          <!-- Informações Cliente -->
          <v-stepper-items>
            <v-stepper-content step="1">
              <template>
                <contratanteInfo
                  v-if="dialogGerarContrato"
                  ref="dadosContratante"
                  @dadosContratanteInfo="capturarInfoContratante"
                  @atualizar-botao-liberado="atualizarBotaoLiberado"
                  @data-contratante="recebeInfos"
                  @ja-cadastrado="buscaRealizada"
                  :ordem="item.ordem"
                  :titulo="item.nome"
                  :campos="campos[0]"
                  :error="error"
                />
              </template>

              <v-btn
                small
                color="error"
                class="buttom-steps"
                @click="fecharModalContrato"
              >
                <v-icon>mdi-close</v-icon>
                Cancelar
              </v-btn>

              <v-btn
                small
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarInfoContratante"
                :disabled="!botaoLiberado"
              >
                Próximo
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>

          <!-- Informações de Endereço do Cliente -->
          <v-stepper-items>
            <v-stepper-content step="2">
              <template>
                <contratanteEndereco
                  v-if="dialogGerarContrato"
                  ref="enderecoContratante"
                  @dadosEnderecoContratante="capturarEnderecoContratante"
                  @atualizar-botao-liberado-endereco="
                    atualizarBotaoLiberadoEndereco
                  "
                  :ordem="item.ordem"
                  :titulo="item.nome"
                  :campos="campos[1]"
                  :infoCliente="infoClientesParaEmit"
                />
              </template>

              <v-btn
                small
                color="warning"
                class="buttom-steps"
                @click="backStep(item.passos, 2)"
              >
                <v-icon>mdi-arrow-left-bold</v-icon>
                Voltar
              </v-btn>

              <v-btn
                small
                color="error"
                class="buttom-steps"
                @click="fecharModalContrato"
              >
                <v-icon>mdi-close</v-icon>
                Cancelar
              </v-btn>

              <v-btn
                small
                v-if="item.value !== 'cancelamento_particular'"
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarEnderecoContratante(false)"
                :disabled="!botaoLiberadoEndereco"
              >
                Próximo
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>

              <v-btn
                small
                v-if="item.value === 'cancelamento_particular'"
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarEnderecoContratante(true)"
                :disabled="!botaoLiberadoEndereco"
              >
                <v-icon>mdi-check</v-icon>
                Finalizar
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>

          <!-- Informações PET's -->
          <v-stepper-items
            v-if="!['cancelamento_particular'].includes(item.value)"
          >
            <v-stepper-content step="3">
              <template>
                <pets
                  v-if="dialogGerarContrato"
                  ref="petsContratante"
                  @dadosPets="capturarPetsContratante"
                  @atualizar-botao-liberado="atualizarBotaoLiberado"
                  :ordem="item.ordem"
                  :titulo="item.nome"
                  :campos="campos[2]"
                  :infoCliente="infoClientesParaEmit"
                />
              </template>

              <v-btn
                small
                color="warning"
                class="buttom-steps"
                @click="backStep(item.passos, 3)"
              >
                <v-icon>mdi-arrow-left-bold</v-icon>
                Voltar
              </v-btn>

              <v-btn
                small
                color="error"
                class="buttom-steps"
                @click="fecharModalContrato"
              >
                <v-icon>mdi-close</v-icon>
                Cancelar
              </v-btn>

              <v-btn
                small
                v-if="
                  ![
                    'inclusao_particular',
                    'exclusao_associado',
                    'exclusao_particular',
                  ].includes(item.value)
                "
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarPetsContratante(false)"
                :disabled="!botaoLiberado"
              >
                Próximo
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>

              <v-btn
                small
                v-if="
                  [
                    'inclusao_particular',
                    'exclusao_associado',
                    'exclusao_particular',
                  ].includes(item.value)
                "
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarPetsContratante(true)"
                :disabled="!botaoLiberado"
              >
                <v-icon>mdi-check</v-icon>
                Finalizar
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>

          <!-- Informações Adesão -->
          <v-stepper-items
            v-if="
              ['inclusao_associado', 'contrato_adesao'].includes(item.value)
            "
          >
            <v-stepper-content step="4">
              <template>
                <adesao
                  v-if="dialogGerarContrato"
                  ref="adesaoContratante"
                  @dadosAdesao="capturarAdesaoContratante"
                  @atualizar-botao-liberado="atualizarBotaoLiberado"
                  :ordem="item.ordem"
                  :titulo="item.nome"
                  :campos="campos[3]"
                  :infoCliente="infoClientesParaEmit"
                  :dadosPets="pets"
                />
              </template>

              <v-btn
                small
                color="warning"
                class="buttom-steps"
                @click="backStep(item.passos, 4)"
              >
                <v-icon>mdi-arrow-left-bold</v-icon>
                Voltar
              </v-btn>

              <v-btn
                small
                color="error"
                class="buttom-steps"
                @click="fecharModalContrato"
              >
                <v-icon>mdi-close</v-icon>
                Cancelar
              </v-btn>

              <v-btn
                small
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarAdesaoContratante"
                :disabled="!botaoLiberado"
              >
                Próximo
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>

          <!-- Informações Mensalidade -->
          <v-stepper-items
            v-if="
              ['inclusao_associado', 'contrato_adesao'].includes(item.value)
            "
          >
            <v-stepper-content step="5">
              <template>
                <mensalidade
                  v-if="dialogGerarContrato"
                  ref="mensalidadeContratante"
                  @dadosMensalidade="capturarMensalidadeContratante"
                  @atualizar-botao-liberado="atualizarBotaoLiberado"
                  :ordem="item.ordem"
                  :titulo="item.nome"
                  :campos="campos[4]"
                  :infoCliente="infoClientesParaEmit"
                  :dadosPets="pets"
                  :jaCadastrado="jaCadastrado"
                />
              </template>

              <v-btn
                small
                color="warning"
                class="buttom-steps"
                @click="backStep(item.passos, 5)"
              >
                <v-icon>mdi-arrow-left-bold</v-icon>
                Voltar
              </v-btn>

              <v-btn
                small
                color="error"
                class="buttom-steps"
                @click="fecharModalContrato"
              >
                <v-icon>mdi-close</v-icon>
                Cancelar
              </v-btn>

              <v-btn
                small
                v-if="!['inclusao_associado'].includes(item.value)"
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarMensalidadeContratante(false)"
                :disabled="!botaoLiberado"
              >
                Próximo
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>

              <v-btn
                small
                v-if="['inclusao_associado'].includes(item.value)"
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarMensalidadeContratante(true)"
                :disabled="!botaoLiberado"
              >
                <v-icon>mdi-check</v-icon>
                Finalizar
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>

          <!-- Informações Forma Pagamento -->
          <v-stepper-items
            v-if="
              [
                'cremacao_particular',
                'cremacao_particular_multiplos',
                'contrato_adesao',
              ].includes(item.value)
            "
          >
            <v-stepper-content step="6">
              <template>
                <formaPagamento
                  v-if="dialogGerarContrato"
                  ref="formaPagamentoContratante"
                  @dadosPagamento="capturarFormaPagamentoContratante"
                  @atualizar-botao-liberado="atualizarBotaoLiberado"
                  :ordem="item.ordem"
                  :titulo="item.nome"
                  :campos="campos[5]"
                  :infoCliente="infoClientesParaEmit"
                />
              </template>

              <v-btn
                small
                color="warning"
                class="buttom-steps"
                @click="backStep(item.passos, 6)"
              >
                <v-icon>mdi-arrow-left-bold</v-icon>
                Voltar
              </v-btn>

              <v-btn
                small
                color="error"
                class="buttom-steps"
                @click="fecharModalContrato"
              >
                <v-icon>mdi-close</v-icon>
                Cancelar
              </v-btn>

              <v-btn
                small
                color="#007744"
                class="white--text buttom-steps"
                @click="cadastrarFormaPagamentoContratante((finalizar = true))"
              >
                <v-icon>mdi-check</v-icon>
                Finalizar
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <!-- Menu com os filtros dos Contratos -->
    <v-card class="mb-3" elevation="0">
      <v-card-title>
        <strong class="titulo-header-page">Contratos Gerados</strong>
        <v-spacer></v-spacer>
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              color="#007744"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
              Gerar Contrato
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in tipo_contrato"
              :key="index"
              @click="abrirModalContrato(item)"
            >
              <v-list-item-title>{{ item.nome }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <div>
        <v-row dense>
          <v-col cols="12" md="12">
            <div class="pl-5 pr-5">
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-text-field
                    color="#007744"
                    label="Nome do Documento:"
                    v-model="buscarPorNome"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    item-value="status"
                    item-text="status"
                    label="Status Assinatura:"
                    color="#007744"
                    :items="status_assinatura"
                    v-model="buscarPorStatus"
                    auto-select-first
                    outlined
                    dense
                    chips
                    clearable
                    deletable-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    item-value="nome"
                    item-text="nome"
                    label="Tipo de Contrato:"
                    color="#007744"
                    v-model="buscarPorContrato"
                    :items="tipo_contrato"
                    auto-select-first
                    outlined
                    dense
                    chips
                    clearable
                    deletable-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    color="#007744"
                    label="Data Inicial:"
                    type="date"
                    v-model="dataIni"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    color="#007744"
                    label="Data Final:"
                    type="date"
                    v-model="dataFim"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-col>
         
          <v-col cols="12" md="12">
            <div class="pl-5 pr-5 margin-negativa">
              <div class="titulo-header-page-legenda">
                <strong>Legenda:</strong>
              </div>
              <v-row dense>
                <v-col cols="6" md="3">
                  <v-chip label class="total" color="orange lighten-3">
                    Aguardando Signatários
                  </v-chip>
                </v-col>
                <v-col cols="6" md="3">
                  <v-chip label class="total" color="blue lighten-3">
                    Aguardando Assinaturas
                  </v-chip>
                </v-col>
                <v-col cols="6" md="3">
                  <v-chip label class="total" color="green lighten-3">
                    Contrato Concluído
                  </v-chip>
                </v-col>
                <v-col cols="6" md="3">
                  <v-chip label class="total" color="red lighten-3">
                    Contrato Cancelado
                  </v-chip>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- Lista dos contratos gerados -->
    <loading v-if="carregamento" />
    <found v-else-if="!carregamento && contratos.length === 0" />
    <v-row v-else justify="center" class="pl-3 pr-3 pt-3">
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(item, i) in contratos" :key="i">
          <v-expansion-panel-header
            disable-icon-rotate
            :color="
              item.status_nome === 'Aguardando Signatários'
                ? 'orange lighten-3'
                : item.status_nome === 'Aguardando Assinaturas'
                ? 'blue lighten-3'
                : item.status_nome === 'Cancelado'
                ? 'red lighten-3'
                : 'green lighten-3'
            "
          >
            <strong>
              <v-icon v-if="item.status_nome === 'Finalizado'" class="mr-2"
                >mdi-file-check</v-icon
              >
              <v-icon v-if="item.status_nome === 'Cancelado'" class="mr-2"
                >mdi-file-remove</v-icon
              >
              <v-icon
                v-if="item.status_nome === 'Aguardando Assinaturas'"
                class="mr-2"
                >mdi-file-clock</v-icon
              >
              <v-icon
                v-if="item.status_nome === 'Aguardando Signatários'"
                class="mr-2"
                >mdi-file-alert</v-icon
              >
              {{ item.nome_documento }}
            </strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list flat>
              <v-subheader>Dados do Documento</v-subheader>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="mt-1">
                    <strong>Nome do Documento:</strong>
                    {{ item.nome_documento }}
                  </v-list-item-title>
                  <v-list-item-title class="mt-1">
                    <strong>ID do Documento:</strong> {{ item.uuid }}
                  </v-list-item-title>
                  <v-list-item-title class="mt-1">
                    <strong>Status:</strong> {{ item.status_nome }}
                  </v-list-item-title>
                  <v-list-item-title class="mt-1">
                    <strong>Data de Criação:</strong>
                    {{ formatDate(item.created_at) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-btn
                  small
                  color="#007744"
                  class="mr-2 white--text"
                  @click="abrirModalExibirContrato(item)"
                >
                  <v-icon>mdi-eye</v-icon> &nbsp;Visualizar Contrato
                </v-btn>
                <v-btn
                  small
                  color="error"
                  class="mr-2 white--text"
                  @click="cancelarDocumento(item.uuid)"
                  v-if="visualizarItemContrato(item)"
                  :loading="loadingCancel"
                >
                  <v-icon>mdi-close</v-icon> &nbsp;Cancelar
                </v-btn>

                <v-btn
                  small
                  color="success"
                  class="mr-2 white--text"
                  :loading="loadingCadastrarNovoTitular"
                  v-if="showCadastrarNovoTitular(item)"
                  @click="cadastrarNovoTitular(item.uuid)"
                >
                  <v-icon>mdi-clipboard-account-outline</v-icon> &nbsp;Cadastrar
                  Titular
                </v-btn>
                <v-btn
                  small
                  color="success"
                  class="mr-2 white--text"
                  :loading="loadingCadastrarNovoDependente"
                  v-if="showIncluirNovoDependente(item)"
                  @click="cadastrarNovoDependente(item.uuid)"
                >
                  <v-icon>mdi-clipboard-account-outline</v-icon> &nbsp;Incluir
                  Dependente
                </v-btn>
                <v-btn
                  small
                  color="warning"
                  class="mr-2 white--text"
                  :loading="loadingExcluirNovoDependente"
                  v-if="showExcluirNovoDependente(item)"
                  @click="fecharAbrirModalExcluirDependente(item.uuid)"
                >
                  <v-icon>mdi-delete</v-icon> &nbsp;Excluir Dependente
                </v-btn>
                <v-btn
                  small
                  color="error"
                  class="mr-2 white--text"
                  :loading="loadingCancelarNovoTitular"
                  v-if="showCancelarTitular(item)"
                  @click="cancelarTitular(item.uuid)"
                >
                  <v-icon>mdi-close</v-icon> &nbsp;Cancelar Plano Titular
                </v-btn>
                <v-btn
                  small
                  color="success"
                  class="mr-2 white--text"
                  :loading="loadingCadastrarAvulso"
                  v-if="showCadastrarAvulso(item)"
                  @click="cadastrarAvulso(item.uuid, item.nome_documento)"
                >
                  <v-icon>mdi-clipboard-account-outline</v-icon> &nbsp;Cadastrar
                  Contrato
                </v-btn>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="text-left mt-5">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.lastPage"
          :total-visible="7"
          color="#007744"
          @input="
            (page) =>
              filtrou ? buscarContratosFiltros(page) : buscarContratos(page)
          "
          v-if="contratos.length > 0"
          @next="
            () =>
              filtrou
                ? buscarContratosFiltros(pagination.page)
                : buscarContratos(pagination.page)
          "
          @previous="
            () =>
              filtrou
                ? buscarContratosFiltros(pagination.page)
                : buscarContratos(pagination.page)
          "
        ></v-pagination>
      </div>
    </v-row>
  </div>
</template>

<script>
import axios from "../../axios/service_private.js";
import Swal from "sweetalert2";

import contratanteInfo from "./components/D4Components/contratanteInfo.vue";
import contratanteEndereco from "./components/D4Components/contratanteEndereco.vue";
import adesao from "./components/D4Components/adesaoContratante.vue";
import pets from "./components/D4Components/petsContratante.vue";
import formaPagamento from "./components/D4Components/formaPagamento.vue";
import contratoModel from "./components/D4Components/contratoModel.vue";
import mensalidade from "./components/D4Components/mensalidade.vue";

var moment = require("moment");
moment.locale("pt-br");

export default {
  components: {
    contratanteInfo,
    contratanteEndereco,
    adesao,
    pets,
    formaPagamento,
    contratoModel,
    mensalidade,
  },
  data: () => ({
    showLoader: false,
    loadingCancel: false,
    botaoLiberado: false,
    botaoLiberadoEndereco: false,
    contratos: [],
    contrato: {},
    dialogGerarContrato: false,
    dialogExibirContrato: false,
    e1: 1,
    carregamento: false,
    contratoSelecionado: null,
    status_assinatura: [
      { status: "Aguardando Signatários", value: 1 },
      { status: "Aguardando Assinaturas", value: 2 },
      { status: "Contrato Concluído", value: 3 },
      { status: "Contrato Arquivado", value: 4 },
      { status: "Contrato Cancelado", value: 5 },
      { status: "Editando", value: 6 },
    ],
    tipo_contrato: [
      {
        nome: "Contrato PET Primavera - Particular",
        value: "cremacao_particular",
        ordem: 1,
        passos: [1, 2, 3, 6],
      },
      {
        nome: "Contrato PET Primavera - Dourados - Plano PET individualizado",
        value: "contrato_adesao",
        ordem: 2,
        passos: [1, 2, 3, 4, 5, 6],
      },
      {
        nome: "Contrato PET Primavera - Particular - Multiplos pets",
        value: "cremacao_particular_multiplos",
        ordem: 3,
        passos: [1, 2, 3, 6],
      },
      {
        nome: "PET PRIMAVERA - DECLARAÇÃO DE CANCELAMENTO DO PLANO PARTICULAR",
        value: "cancelamento_particular",
        ordem: 4,
        passos: [1, 2],
      },
      {
        nome: "TABLET VENDAS - TERMO DE INCLUSÃO DE DEPENDENTE PET PRIMAVERA ASSOCIADO",
        value: "inclusao_associado",
        ordem: 5,
        passos: [1, 2, 3, 4, 5],
      },
      {
        nome: "PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET ASSOCIADO",
        value: "exclusao_associado",
        ordem: 6,
        passos: [1, 2, 3],
      },
      {
        nome: "PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET PARTICULAR",
        value: "exclusao_particular",
        ordem: 7,
        passos: [1, 2, 3],
      },
      {
        nome: "PET PRIMAVERA - DECLARAÇÃO DE INCLUSÃO DE DEPENDENTE PET PARTICULAR",
        value: "inclusao_particular",
        ordem: 8,
        passos: [1, 2, 3],
      },
    ],
    contratanteId: null,

    /// Campos para exibir ou não
    campos: [
      {
        cpf: [1, 2, 3, 4, 5, 6, 7, 8],
        nome_completo: [1, 2, 3, 4, 5, 6, 7, 8],
        rg: [1, 2, 3, 4, 5, 6, 7, 8],
        naturalidade: [1, 2, 3],
        nacionalidade: [1, 2, 3],
        data_nasc: [1, 2, 3],
        telefone: [1, 2, 3, 4, 5, 6, 7, 8],
        telefone2: [1, 2, 3],
        data_assinatura: [1, 2, 3, 4, 5, 6, 7, 8],
        email: [1, 2, 3, 5, 6, 7, 8],
        estado_civil: [2],
        n_contrato: [4, 5, 6, 7, 8],
        data_cadastro: [4],
        data_cancelamento: [4],
        mensalidades_vencidas: [4],
        motivo_cancelamento: [4],
        religiao: [2],
      },
      {
        logradouro: [1, 2, 3, 4, 5, 6, 7, 8],
        numero: [2, 5, 6, 7, 8],
        bairro: [1, 2, 3, 5, 6, 7, 8],
        estado: [1, 2, 3, 4, 5, 6, 7, 8],
        cidade: [1, 2, 3, 4, 5, 6, 7, 8],
        cep: [1, 2, 3, 5, 6, 7, 8],
        obs: [1, 2, 3, 5, 6, 7, 8],
      },
      {
        nome_pet: [1, 2, 3, 5, 6, 7, 8],
        cor_pet: [1, 2, 3, 5, 6, 7, 8],
        data_nasc_pet: [1, 2, 3, 5, 6, 7, 8],
        modalidade_cremacao: [1, 2, 3, 5, 6, 7, 8],
        especie_pet: [1, 2, 3, 5, 6, 7, 8],
        raca_pet: [1, 2, 3, 5, 6, 7, 8],
        peso_pet: [1, 2, 3, 5, 6, 7, 8],
        porte_pet: [1, 2, 3, 5, 6, 7, 8],
        altura_pet: [1, 2, 3, 5, 6, 7, 8],
        data_obito_pet: [1, 3],
      },
      {
        valor_adesao: [2],
        valor_adesao_extenso: [2],
        valor_adesao_adicionais: [2],
        valor_adesao_adicionais_extenso: [2],
        valor_total_adesao: [2, 5],
        valor_total_adesao_extenso: [2],
      },
      {
        valor_mensalidade: [2],
        valor_mensalidade_extenso: [2],
        valor_mensalidade_adicionais: [2],
        valor_mensalidade_adicionais_extenso: [2],
        valor_total_mensalidade: [2, 5],
        valor_total_mensalidade_extenso: [2],
      },
      {
        forma_pagamento: [1, 2, 3],
        data_primeira_mensalidade: [2],
        valor_total_servico: [1, 3],
        dia_pagamento: [2],
      },
    ],

    item: {},

    info: null,
    endereco: null,
    adesao: null,
    mensalidade: null,
    pets: null,
    pagamento: null,
    error: {},
    finalEnd: false,
    finalMensalidade: false,
    finalPet: false,

    infoClientesParaEmit: null,
    petsParaEmit: null,
    jaCadastrado: [],

    pagination: {
      page: 1,
      perPage: 1,
      lastPage: 1,
      total: null,
    },

    buscar: {},

    buscarPorStatus: null,
    buscarPorNome: null,
    buscarPorContrato: null,
    dataIni: null,
    dataFim: null,
    dialogExcluirDependente: null,
    nomePetParaExcluir: null,
    nomesPetsExcluir: [],
    uuidTitularPetExcluir: null,

    filtrou: false,

    loadingCadastrarNovoTitular: false,
    loadingCadastrarNovoDependente: false,
    loadingExcluirNovoDependente: false,
    loadingCancelarNovoTitular: false,
    loadingCadastrarAvulso: false,
  }),
  methods: {
    /// Métodos para mostrar ou não botões de cadastro
    showCadastrarNovoTitular(item) {
      return (
        item.tipo_contrato ===
          "Contrato PET Primavera - Dourados - Plano PET individualizado" &&
        item.status_nome === "Finalizado"
      );
    },
    showExcluirNovoDependente(item) {
      return (
        item.tipo_contrato ===
          "PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET PARTICULAR" &&
        item.status_nome === "Finalizado"
      );
    },
    showIncluirNovoDependente(item) {
      return (
        item.tipo_contrato ===
          "PET PRIMAVERA - DECLARAÇÃO DE INCLUSÃO DE DEPENDENTE PET PARTICULAR" &&
        item.status_nome === "Finalizado"
      );
    },
    showCancelarTitular(item) {
      return (
        item.tipo_contrato ===
          "PET PRIMAVERA - DECLARAÇÃO DE CANCELAMENTO DO PLANO PARTICULAR" &&
        item.status_nome === "Finalizado"
      );
    },
    showCadastrarAvulso(item) {
      return (
        (item.tipo_contrato === "Contrato PET Primavera - Particular" ||
          item.tipo_contrato ===
            "Contrato PET Primavera - Particular - Multiplos pets" ||
          item.tipo_contrato ===
            "TABLET VENDAS - TERMO DE INCLUSÃO DE DEPENDENTE PET PRIMAVERA ASSOCIADO" ||
          item.tipo_contrato ===
            "PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET ASSOCIADO") &&
        item.status_nome === "Finalizado"
      );
    },
    fecharAbrirModalExcluirDependente(uuid) {
      this.uuidTitularPetExcluir = uuid;
      this.dialogExcluirDependente = !this.dialogExcluirDependente;
      this.nomePetParaExcluir = null;
      this.nomesPetsExcluir = [];
    },
    addPetExcluir() {
      this.nomesPetsExcluir.push(this.nomePetParaExcluir);
      this.nomePetParaExcluir = null;
    },
    visualizarItemContrato(item) {
      if (item.status_nome === "Finalizado" || item.status_nome === "Cancelado")
        return false;
      else return true;
    },
    async buscarContratos(params) {
      this.carregamento = true;

      try {
        const contratos = await axios.get(
          "api/v1/pet-primavera/contratos/listar-documentos-cofre",
          {
            params: { page: params },
          }
        );

        if (contratos.data.status) {
          this.carregamento = false;
          const data = contratos.data.data;
          this.contratos = contratos.data.data.data;

          this.pagination = {
            page: data.currentPage,
            perPage: data.perPage,
            lastPage: data.lastPage,
            total: data.total,
          };
        }
      } catch (error) {
        this.carregamento = false;
        this.$toast(error.response.data.message, {
          type: "error",
        });
      }
    },
    async buscarContratosFiltros(tipo, search = null) {
      if (
        this.buscarPorNome !== null ||
        this.buscarPorStatus !== null ||
        this.buscarPorContrato !== null ||
        this.dataIni !== null ||
        this.dataFim !== null
      ) {
        this.filtrou = true;
      } else {
        this.filtrou = false;
      }

      this.carregamento = true;

      if (tipo === "buscar_por_nome") {
        this.buscar.nome_documento = search;
      }

      if (tipo === "buscar_por_status") {
        this.buscar.status_assinatura = search;
      }

      if (tipo === "buscar_por_contrato") {
        this.buscar.tipo_contrato = search;
      }

      if (tipo == "buscar_por_data_ini") {
        this.buscar.dataIni = search;
      }

      if (tipo === "buscar_por_data_fim") {
        this.buscar.dataFim = search;
      }

      this.buscar.page = this.pagination.page;

      try {
        const contratos = await axios.get(
          "api/v1/pet-primavera/contratos/buscar",
          {
            params: this.buscar,
          }
        );

        if (contratos.data.status) {
          this.carregamento = false;
          this.contratos = contratos.data.data.data;

          this.pagination = {
            page: contratos.data.data.currentPage,
            perPage: contratos.data.data.perPage,
            lastPage: contratos.data.data.lastPage,
            total: contratos.data.data.total,
          };

          this.buscar = {};
        }
      } catch (error) {
        this.$toast(error.response.data.message, {
          type: "error",
        });
      }
      //if (this.buscar.tipo === null && this.buscar.filtro === null) return this.buscarContratos(); // Verificar o parametro em buscarContratos()
    },
    abrirModalExibirContrato(item) {
      this.contrato = item;
      this.dialogExibirContrato = true;
    },
    fecharModalExibirContrato() {
      this.dialogExibirContrato = false;
    },
    async cancelarDocumento(id) {
      Swal.fire({
        title: "Cancelar?",
        text: "Algumas ações são irreversíveis. Tem certeza que deseja cancelar este documento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#007744",
        cancelButtonText: "Fechar",
        confirmButtonText: "CANCELAR",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingCancel = true;
          try {
            let cancelar = await axios.post(
              "api/v1/pet-primavera/contratos/cancelar-documento",
              {
                uuid_doc: id,
                comment: "Cancelamento de Contrato - PET Primavera",
              }
            );

            if (cancelar && cancelar.data.status) {
              await this.buscarContratos(this.pagination.page);
              this.loadingCancel = false;
              this.$toast("Documento cancelado com sucesso!", {
                type: "success",
              });
            } else {
              this.loadingCancel = false;
              this.$toast(cancelar.data.data.data.mensagem_pt, {
                type: "warning",
              });
            }
          } catch (error) {
            this.loadingCancel = false;
            this.$toast("Erro ao cancelar documento!", {
              type: "error",
            });
          }
        }
      });
    },
    abrirModalContrato(item) {
      if (Object.keys(item).length !== 0) {
        this.item = item;
      }
      this.dialogGerarContrato = !this.dialogGerarContrato;
      this.e1 = 1;
    },
    fecharModalContrato() {
      this.dialogGerarContrato = !this.dialogGerarContrato;
      this.e1 = 1;
      this.item = {};
    },
    backStep(item, passoAtual) {
      const passosAnteriores = item.filter((passo) => passo < passoAtual);
      this.e1 = passosAnteriores.pop(); // e1 recebe o passo anterior
    },
    nextStep(item, passoAtual) {
      this.e1 = item.find((passo) => passo > passoAtual); // e1 recebe o próximo passo
    },
    atualizarBotaoLiberado(valor) {
      this.botaoLiberado = valor;
    },
    atualizarBotaoLiberadoEndereco(valor) {
      this.botaoLiberadoEndereco = valor;
    },
    async cadastrar(
      dados,
      tipo,
      item,
      passoAtual,
      finalizar = false,
      contrato = null
    ) {
      const url = "/api/v1/pet-primavera/contratos";
      let route = "";

      if (tipo !== "cliente" && tipo !== "pets")
        dados.contratante_id = this.contratanteId;

      switch (tipo) {
        case "cliente":
          route = !this.jaCadastrado[0]
            ? "/cadastrar-cliente"
            : "/atualizar-infos";
          break;
        case "endereco":
          route = !this.jaCadastrado[1]
            ? "/cadastrar-endereco-cliente"
            : "/atualizar-endereco";
          break;
        case "adesao":
          route = !this.jaCadastrado[2]
            ? "/adesao-cliente"
            : "/atualizar-adesao";
          break;
        case "mensalidade":
          route = !this.jaCadastrado[3]
            ? "/mensalidade-cliente"
            : "/atualizar-parcelas";
          break;
        case "pets":
          if (
            !this.jaCadastrado[5] ||
            this.item.nome ===
              "PET PRIMAVERA - DECLARAÇÃO DE INCLUSÃO DE DEPENDENTE PET PARTICULAR"
          ) {
            dados.forEach((element) => {
              element.contratante_id = this.contratanteId;
            });
            route = "/cadastrar-pets";
          } else {
            route = "/atualizar-pets";
          }
          break;
        case "pagamento":
          route = !this.jaCadastrado[4]
            ? "/cadastrar-pagamento"
            : "/atualizar-pagamento";
          break;
        default:
          this.$toast("Erro ao cadastrar ou atualizar, contate o suporte", {
            type: "error",
          });
          break;
      }

      await axios
        .post(url + route, dados)
        .then((res) => {
          Swal.fire({
            title: "Sucesso!",
            text: res.data.message,
            icon: "success",
            confirmButtonColor: "#007744",
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: true,
          }).then(async (result) => {
            if (tipo === "cliente" && !this.jaCadastrado[0]) {
              this.contratanteId = res.data.data;
              this.info.id = res.data.data;
              this.jaCadastrado[0] = true;
            }
            if (tipo === "endereco" && !this.jaCadastrado[1]) {
              this.jaCadastrado[1] = true;
              this.endereco.id = res.data.data;
            }
            if (tipo === "adesao" && !this.jaCadastrado[2]) {
              this.jaCadastrado[2] = true;
              this.adesao.id = res.data.data;
            }
            if (tipo === "mensalidade" && !this.jaCadastrado[3]) {
              this.jaCadastrado[3] = true;
              this.mensalidade.id = res.data.data;
            }
            if (tipo === "pagamento" && !this.jaCadastrado[4]) {
              this.jaCadastrado[4] = true;
              this.pagamento.id = res.data.data;
            }
            if (tipo === "pets" && !this.jaCadastrado[5]) {
              this.jaCadastrado[5] = true;
              let ids = res.data.data;
              ids.forEach((id, index) => {
                this.pets[index].id = id;
              });
            }

            if (result.isConfirmed) {
              if (!finalizar) {
                this.nextStep(item, passoAtual);
              } else {
                this.e1 = 1;
                this.sendInformacoesContrato(contrato);
              }
            }
          });
        })
        .catch((error) => {
          if (error) {
            this.error = error.response.data.validation;
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
        });
    },
    recebeInfos(dados) {
      this.contratanteId = dados.id_contratante_table ?? null;
      this.infoClientesParaEmit = dados;
    },
    recebePets(dados) {
      this.petsParaEmit = dados;
    },
    buscaRealizada(dados) {
      this.jaCadastrado = dados;
    },

    /// Cadastrar Informações Contratante
    cadastrarInfoContratante() {
      this.$refs.dadosContratante.emitDados();
    },
    capturarInfoContratante(dados) {
      this.info = dados;
      this.cadastrar(dados, "cliente", this.item.passos, 1);
    },

    /// Cadastrar Informações Endereço Contratante
    cadastrarEnderecoContratante(finalizar = false) {
      this.finalEnd = finalizar;
      this.$refs.enderecoContratante.emitDados();
    },
    capturarEnderecoContratante(dados) {
      this.endereco = dados;
      this.cadastrar(
        dados,
        "endereco",
        this.item.passos,
        2,
        this.finalEnd,
        this.item.ordem
      );
    },

    /// Cadastrar Informações Adesao Contratante
    cadastrarAdesaoContratante() {
      this.$refs.adesaoContratante.emitDados();
    },
    capturarAdesaoContratante(dados) {
      this.adesao = dados;
      this.cadastrar(dados, "adesao", this.item.passos, 4);
    },

    /// Cadastrar Informações Mesalidade Contratante
    cadastrarMensalidadeContratante(finalizar = false) {
      this.finalMensalidade = finalizar;
      this.$refs.mensalidadeContratante.emitDados();
    },
    capturarMensalidadeContratante(dados) {
      this.mensalidade = dados;
      this.cadastrar(
        dados,
        "mensalidade",
        this.item.passos,
        5,
        this.finalMensalidade,
        this.item.ordem
      );
    },

    /// Cadastrar Informações Pet Contratante
    cadastrarPetsContratante(finalizar = false) {
      this.finalPet = finalizar;
      this.$refs.petsContratante.emitDados();
    },
    async capturarPetsContratante(dados) {
      this.pets = dados;

      // Recebe o cpf do titular em caso de adesão/exclusão de dependente
      if (
        this.item.nome ===
          "PET PRIMAVERA - DECLARAÇÃO DE INCLUSÃO DE DEPENDENTE PET PARTICULAR" ||
        this.item.nome ===
          "PET PRIMAVERA - DECLARAÇÃO DE EXCLUSÃO DE DEPENDENTE PET PARTICULAR"
      ) {
        dados.forEach((element) => {
          element.cpf_contratante = this.info.cpf;
        });
      }
      await this.cadastrar(
        dados,
        "pets",
        this.item.passos,
        3,
        this.finalPet,
        this.item.ordem
      );
    },

    /// Cadastrar Informações Forma de Pagamento Contratante
    cadastrarFormaPagamentoContratante() {
      this.$refs.formaPagamentoContratante.emitDados();
    },
    capturarFormaPagamentoContratante(dados) {
      this.pagamento = dados;
      this.cadastrar(
        dados,
        "pagamento",
        this.item.passos,
        6,
        true,
        this.item.ordem
      );
    },

    abrirModalContratoGerado(uuid) {
      this.contratos.forEach((contrato) => {
        if (contrato.uuid === uuid) abrirModalExibirContrato(contrato);
      });
    },
    async sendInformacoesContrato(contrato) {
      this.dialogGerarContrato = false;

      let data = [];

      if ([1, 3].includes(contrato)) {
        data = [this.info, this.endereco, this.pets, this.pagamento];

        this.gerarContrato(data, this.item.nome, this.info.cpf);
      } else if ([2].includes(contrato)) {
        data = [
          this.info,
          this.endereco,
          this.adesao,
          this.mensalidade,
          this.pets,
          this.pagamento,
        ];
        this.gerarContrato(data, this.item.nome, this.info.cpf);
      } else if ([4].includes(contrato)) {
        data = [this.info, this.endereco];

        this.gerarContrato(data, this.item.nome, this.info.cpf);
      } else if ([5].includes(contrato)) {
        data = [
          this.info,
          this.endereco,
          this.adesao,
          this.mensalidade,
          this.pets,
        ];

        this.gerarContrato(data, this.item.nome, this.info.cpf);
      } else if ([6, 7, 8].includes(contrato)) {
        data = [this.info, this.endereco, this.pets];

        this.gerarContrato(data, this.item.nome, this.info.cpf);
      }
    },
    async gerarContrato(data, nome, cpf) {
      try {
        this.showLoader = true;
        await axios
          .post("/api/v1/pet-primavera/contratos/gerar-documento", {
            nome_template: nome,
            dados_documento: data,
          })
          .then(async (res) => {
            if (res && res.data.status) {
              this.showLoader = false;
              Swal.fire({
                title: "Sucesso!",
                text: `ID do documento: ${res.data.data.uuid}`,
                icon: "success",
                confirmButtonColor: "#007744",
              });

              await this.cadastrarDocumentoBase(
                data,
                res.data.data.uuid,
                nome,
                cpf
              );
              await this.buscarContratos(this.pagination.page);
            } else {
              this.showLoader = false;
              this.$toast(res.data.data.message, {
                type: "error",
              });
            }
          })
          .catch((error) => {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          });
      } catch (error) {
        if (error.response && error.response.data.status) {
          this.$toast(error.response.data.message, {
            type: "error",
          });
        } else {
          this.$toast(error.response, {
            type: "error",
          });
        }
      }
    },
    async cadastrarDocumentoBase(data, uuid_doc, nome, cpf) {
      try {
        const info = {
          uuid_doc: uuid_doc,
          data_assinatura: data[0].data_assinatura,
          n_contrato: data[0].n_contrato,
          tipo_contrato: nome,
          cpf_contratante: cpf,
        };
        /// Registra as informações do contrato na base de dados
        await axios
          .post("/api/v1/pet-primavera/contratos/cadastrar-documento", {
            dados: info,
          })
          .then((res) => {
            if (res.data.status) {
              this.$toast("Documento gerado e cadastrado com sucesso!", {
                type: "success",
              });
            } else {
              this.$toast("Erro ao cadastrar documento!", {
                type: "error",
              });
            }
          });
      } catch (error) {
        this.$toast(error.response.data.message, {
          type: "error",
        });
      }
    },
    async cadastrarNovoTitular(uuidDoc) {
      this.loadingCadastrarNovoTitular = true;
      await axios
        .post("api/v1/pet-primavera/contratos/cadastrar-novo-titular", {
          uuid_doc: uuidDoc,
        })
        .then((res) => {
          if (res.data.status) {
            this.loadingCadastrarNovoTitular = false;
            this.$toast(res.data.message, {
              type: "success",
            });
          } else {
            this.loadingCadastrarNovoTitular = false;
            this.$toast(res.data.message, {
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loadingCadastrarNovoTitular = false;
          if (error.response.data && error.response.data.message) {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
        });
    },
    async cancelarTitular(uuidDoc) {
      this.loadingCancelarNovoTitular = true;
      await axios
        .post("api/v1/pet-primavera/contratos/cancelar-titular", {
          uuid: uuidDoc,
        })
        .then((res) => {
          this.loadingCancelarNovoTitular = false;
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loadingCancelarNovoTitular = false;
          if (error.response.data && error.response.data.message) {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
        });
    },
    async cadastrarNovoDependente(uuidDoc) {
      this.loadingCadastrarNovoDependente = true;
      await axios
        .post("api/v1/pet-primavera/contratos/cadastrar-novo-dependente", {
          uuid: uuidDoc,
        })
        .then((res) => {
          if (res.data.status) {
            this.loadingCadastrarNovoDependente = false;
            this.$toast(res.data.message, {
              type: "success",
            });
          } else {
            this.loadingCadastrarNovoDependente = false;
            this.$toast(res.data.message, {
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loadingCadastrarNovoDependente = false;
          if (error.response.data && error.response.data.message) {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
        });
    },
    async excluirDependente(petsDependente) {
      this.loadingExcluirNovoDependente = true;
      await axios
        .post("api/v1/pet-primavera/contratos/excluir-dependente", {
          uuid: this.uuidTitularPetExcluir,
          pets: petsDependente,
        })
        .then((res) => {
          this.loadingExcluirNovoDependente = false;
          if (res.data.status) {
            this.$toast(res.data.message, {
              type: "success",
            });
            this.dialogExcluirDependente = !this.dialogExcluirDependente;
          } else {
            this.$toast(res.data.message, {
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.loadingExcluirNovoDependente = false;
          if (error.response.data && error.response.data.message) {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
        });
    },
    async cadastrarAvulso(uuidDoc, nomeDocumento) {
      this.loadingCadastrarAvulso = true;
      await axios
        .post("api/v1/pet-primavera/contratos/cadastrar-termo-avulso", {
          uuid: uuidDoc,
          nome: nomeDocumento,
        })
        .then((res) => {
          if (res.data.status) {
            this.loadingCadastrarAvulso = false;
            this.$toast(res.data.message, {
              type: "success",
            });
          } else {
            this.loadingCadastrarAvulso = false;
            this.$toast(res.data.message, {
              type: "warning",
            });
          }
        })
        .catch((error) => {
          this.loadingCadastrarAvulso = false;

          if (error.response.data && !error.response.data.status) {
            this.$toast(error.response.data.error, {
              type: "error",
            });
          } else {
            this.$toast(error.response.data.message, {
              type: "error",
            });
          }
        });
    },
    formatDate(date) {
      const dataFormatada = new Date(date);
      return Intl.DateTimeFormat("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(dataFormatada);
    },
    async setup() {
      this.$store.dispatch("verifyToken");
      await this.buscarContratos(this.pagination.page);
    },
  },
  watch: {
    async buscarPorNome() {
      this.buscarContratosFiltros("buscar_por_nome", this.buscarPorNome);
    },
    async buscarPorStatus() {
      this.buscarContratosFiltros("buscar_por_status", this.buscarPorStatus);
    },
    async buscarPorContrato() {
      this.buscarContratosFiltros(
        "buscar_por_contrato",
        this.buscarPorContrato
      );
    },
    async dataIni() {
      this.buscarContratosFiltros("buscar_por_data_ini", this.dataIni);
    },
    async dataFim() {
      this.buscarContratosFiltros("buscar_por_data_fim", this.dataFim);
    },
  },
  async mounted() {
    await this.setup();
  },
};
</script>

<style scoped>
.space {
  margin: 14px 5px;
}
.v-chip-size {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.informacoes {
  margin-bottom: 2rem;
}
fieldset {
  border: 1px solid lightgray;
}
.itemsContainerContact {
  font-size: 0.8rem;
  font-weight: 400;
  width: 45%;
}
.imagem-pet img {
  width: 40px;
  cursor: pointer;
}
.info-parcela {
  margin-top: -20px;
  margin-bottom: 1rem;
}
.carencia {
  background: rgba(248, 248, 117, 0.904);
}
.deletado {
  background: rgba(212, 97, 89, 0.904);
}
.obito {
  background: rgba(211, 157, 7, 0.904);
}
.divide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cartao-pet {
  padding: 20px 20px;
}
.cartao-pet p,
.cartao-pet b {
  font-size: 14px;
}
.cartao-pet b {
  font-size: 10px;
  padding-right: 20px;
}
.cartao-pet img {
  width: 100%;
  height: 100%;
}
.imagem-pet-icon-horizontal img {
  width: 20px;
}
.imagem-pet-icon img {
  width: 35px;
}
.texto_cartao_pet {
  position: absolute;
  margin-top: -95px;
}
.texto_cartao_raca {
  position: absolute;
  margin-top: -75px;
}
.texto_cartao_tutor {
  position: absolute;
  margin-top: -60px;
}
.dados-cartao {
  padding-left: 10px;
  padding-right: 10px;
}
.cartao-pet {
  padding: 20px 20px;
}
.cartao-pet p,
.cartao-pet b {
  font-size: 14px;
}
.cartao-pet b {
  font-size: 10px;
  padding-right: 20px;
}
.cartao-pet img {
  width: 100%;
  height: 100%;
}
.imagem-pet-icon-horizontal img {
  width: 20px;
}
.imagem-pet-icon img {
  width: 35px;
}
.texto_cartao_pet {
  position: absolute;
  margin-top: -95px;
}
.texto_cartao_raca {
  position: absolute;
  margin-top: -75px;
}
.texto_cartao_tutor {
  position: absolute;
  margin-top: -60px;
}
.texto_cartao_cpf {
  position: absolute;
  margin-top: -45px;
}
.dados-cartao {
  padding-left: 10px;
  padding-right: 10px;
}
.margin-negativa {
  margin-top: -30px;
  margin-bottom: 30px;
}
.estornarObito {
  background: orange;
}
#extrato {
  padding: 20px 20px;
}
#extrato img {
  width: 160px;
}
#extrato h1 {
  font-size: 18px;
  text-align: right;
}
#extrato .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#extrato ul {
  list-style: none;
}
#extrato ul li {
  text-align: right;
}
.v-list-item__title {
  white-space: pre-line;
}
.primary-text {
  color: #007744;
  list-style: none;
  padding: 10px 10px;
  font-weight: bold;
}
.copiar_codigo_pix {
  padding: 20px 20px;
  background: #ececec;
  text-align: center;
}

.baixado {
  background: #007744;
  color: #ddd;
}

.cancelado {
  background: rgb(209, 67, 67);
  color: #ddd;
}

.total {
  width: 100%;
}

.titulo-header-page-legenda {
  margin-top: 10px;
}

.buttom-steps {
  margin-top: 30px;
  margin-right: 20px;
}
</style>
