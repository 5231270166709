import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import { VueMaskDirective } from 'v-mask'
import found from "./views/components/found.vue"
import sistema from "./views/components/sistema.vue"
import loginLoading from "./views/components/loginLoading.vue"
import loading from "./views/components/loading.vue"
import VueHtmlToPaper from 'vue-html-to-paper'
import VueHtml2pdf from 'vue-html2pdf'
import widget from "./views/components/card.vue"

const options = {
  name: '_blank',
  specs: [
  ],
  styles: [
    'css/cartao.css'
  ],
  timeout: 1000, 
  autoClose: true, 
  windowTitle: window.document.title, 
}

Vue.use(VueHtmlToPaper, options);
Vue.use(VueHtmlToPaper);
Vue.config.productionTip = false
Vue.component('found', found)
Vue.component('loading', loading)
Vue.component('sistema', sistema)
Vue.component('loginLoading', loginLoading)
Vue.component('widget', widget);

Vue.directive('mask', VueMaskDirective)
Vue.use(VueHtml2pdf)
Vue.use(Toast, {})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
