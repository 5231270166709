<template>
  <div>
    <div>
      <loading v-if="carregamento" />

      <v-card v-else flat>
        <v-card-title>
          <b class="titulo-header-page">Relatório Anual - Plano Particular</b>
          <v-spacer></v-spacer>
        </v-card-title>

        <div class="ml-5 mr-5">
          <v-row dense>
            <v-col cols="12" md="2">
              <v-text-field
                class="input"
                color="#007744"
                label="Data inicial:"
                v-model="params.inicio"
                required
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                class="input"
                v-model="params.fim"
                color="#007744"
                type="date"
                label="Data final:"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                class="input"
                v-model="params.qtdMes"
                color="#007744"
                type="number"
                label="Meses a mostrar:"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="align-center">
              <v-btn
                color="#007744"
                class="white--text"
                depressed
                @click="this.loadRelatorio"
              >
                <v-icon left>mdi-magnify</v-icon>
                Gerar
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-simple-table dense fixed-header height="500px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Mês/Ano</th>
                <th class="text-center">Permuta</th>
                <th class="text-center">Cancelamentos/Inativos</th>
                <th class="text-center">Novos Dependentes</th>
                <th class="text-center">Novos Contratos</th>
                <th class="text-center">Mensalidades(Pendente)</th>
                <th class="text-center">Mensalidades(Pagas)</th>
                <th class="text-center">Adicionais</th>
                <th class="text-center">Óbitos</th>
                <th class="text-center">Adesões</th>
                <th class="text-center">Total Recebimento Adesões</th>
                <th class="text-center">Total Recebimento Mensalidades</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in relatorio" :key="index">
                <td class="text-center">{{ item.mes_ano }}</td>
                <td class="text-center">{{ item.permuta_contrato }}</td>
                <td class="text-center">{{ item.contratos_cancelados }}</td>
                <td class="text-center">{{ item.novos_dependentes }}</td>
                <td class="text-center">{{ item.novos_contratos }}</td>
                <td class="text-center">{{ item.clientes_nao_pagos }}</td>
                <td class="text-center">{{ item.clientes_pagos }}</td>
                <td class="text-center">{{ item.novos_adicionais }}</td>
                <td class="text-center">{{ item.quantidade_obitos }}</td>
                <td class="text-center">{{ item.quantidade_adesoes }}</td>
                <td class="text-center">
                  R$ {{ formatPreco(item.total_recebimento_adesao) }}
                </td>
                <td class="text-center">
                  R$ {{ formatPreco(item.total_recebimento_mensalidade) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-center"><strong>Total</strong></td>
                <td class="text-center">
                  <b>{{ calcularTotal("permuta_contrato") }}</b>
                </td>
                <td class="text-center">
                  <b>{{ calcularTotal("contratos_cancelados") }}</b>
                </td>
                <td class="text-center">
                  <b>{{ calcularTotal("novos_dependentes") }}</b>
                </td>
                <td class="text-center">
                  <b>{{ calcularTotal("novos_contratos") }}</b>
                </td>
                <td class="text-center">
                  <b>{{ calcularTotal("clientes_nao_pagos") }}</b>
                </td>
                <td class="text-center">
                  <b>{{ calcularTotal("clientes_pagos") }}</b>
                </td>
                <td class="text-center">
                  <b>{{ calcularTotal("novos_adicionais") }}</b>
                </td>
                <td class="text-center">
                  <b>{{ calcularTotal("quantidade_obitos") }}</b>
                </td>
                <td class="text-center">
                  <b> {{ calcularTotal("quantidade_adesoes") }}</b>
                </td>
                <td class="text-center">
                  <b
                    >R$
                    {{
                      formatPreco(calcularTotal("total_recebimento_adesao"))
                    }}</b
                  >
                </td>
                <td class="text-center">
                  <b>
                    R$
                    {{
                      formatPreco(
                        calcularTotal("total_recebimento_mensalidade")
                      )
                    }}</b
                  >
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>
  <script>
import axios from "../../axios/service_private.js";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      carregamento: false,
      relatorio: [],
      usuarios: [],
      params: {
        inicio: moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD"),
        fim: moment().endOf("year").format("YYYY-MM-DD"),
        qtdMes: 12,
      },
    };
  },
  methods: {
    async loadRelatorio() {
      this.relatorio = [];
      this.carregamento = true;

      const relatorio = await axios.get(
        "api/v1/pet-primavera/relatorio/relatorio-geral",
        { params: this.params }
      );

      if (relatorio.data.status) {
        this.relatorio = relatorio.data.relatorio;
        this.carregamento = false;
      }
    },
    formatPreco(value) {
      if (!value) return 0;
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    calcularTotal(campo) {
      return this.relatorio.reduce((total, item) => {
        const valor = parseFloat(item[campo]);
        return total + (isNaN(valor) ? 0 : valor);
      }, 0);
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadRelatorio();
    },
  },
  mounted() {
    this.setup();
  },
};
</script>
  
  <style scoped>
.totais {
  padding: 20px 20px;
  background: #eeeeee;
}

.totais ul {
  list-style: none;
}

.totais li {
  font-weight: bold;
  padding-top: 10px;
}
</style>
