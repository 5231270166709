<template>
  <div>
    <div>
      <v-card flat>
        <v-card-title>
          <b class="titulo-header-page">Relatório Recebimento</b>
          <v-spacer></v-spacer>
        </v-card-title>

        <div class="ml-5 mr-5">
          <v-row dense>
            <v-col cols="12" md="3">
              <v-autocomplete
                class="mb-5"
                item-value="id"
                item-text="nome"
                label="Usuário"
                color="#007744"
                :items="usuarios" 
                v-model="params.usuario"
                auto-select-first
                outlined
                dense
                chips
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                class="input"
                color="#007744"
                label="Data inicial:"
                v-model="params.inicio" 
                required
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                class="input"
                v-model="params.fim" 
                color="#007744"
                type="date"
                label="Data final:"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="align-center">
              <v-btn
                color="#007744"
                class="white--text"
                depressed
                @click="this.loadRelatorio"
              >
                <v-icon left>mdi-magnify</v-icon>
                Pesquisar
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <loading v-if="carregamento" />

        <found v-else-if="!carregamento && relatorio.length == 0" />

        <v-simple-table dense v-else fixed-header height="400px">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Usuário</th>
                <th class="text-left">Titular contrato</th>
                <th class="text-center">Valor Pago</th>
                <th class="text-center">Data Pagamento</th>
                <th class="text-center">Data Vencimento</th>
                <th class="text-left">Plano</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in relatorio" :key="index">
                <td class="text-left">{{ item._usuario }}</td>
                <td class="text-left">{{ item.titular }}</td>
                <td class="text-center">{{ formatPreco(item.valor_pago) }}</td>
                <td class="text-center">
                  {{ dateFormat(item.data_pagamento) }}
                </td>
                <td class="text-center">
                  {{ dateFormat(item.data_referente) }}
                </td>
                <td class="text-left">{{ item._plano }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="totais" v-if="relatorio && relatorio.length > 0">
          <ul>
            <li>Quantidade de parcelas recebidas: {{ relatorio.length }}</li>
            <li>Total recebido: {{ formatPreco(calcularTotal()) }}</li>
          </ul>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import axios from "../../axios/service_private.js";

var moment = require("moment");
moment.locale("pt-br");

export default {
  data() {
    return {
      carregamento: false,
      relatorio: [],
      usuarios: [],
      params: {
        inicio: moment().startOf("month").format("YYYY-MM-DD"),
        fim: moment().endOf("month").format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    loadUsuarios() {
      axios.get("/api/v1/pet-primavera/usuarios-all").then((response) => {
        if (response.data.status) {
          this.usuarios = response.data.usuarios;
        }
      });
    },
    calcularTotal() {
      if (this.relatorio && this.relatorio.length === 0) return 0;
      return this.relatorio
        .map((item) => item.valor_pago)
        .reduce((prev, next) => prev + next);
    },
    dateFormat(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    formatPreco(value) {
      if (!value) return 0;
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async loadRelatorio() {
      this.relatorio = [];
      this.carregamento = true;

      const relatorio = await axios.get(
        "api/v1/pet-primavera/relatorio/baixas",
        {
          params: this.params,
        }
      );

      if (relatorio.data.status) {
        this.relatorio = relatorio.data.relatorio;
        this.carregamento = false;
      }
    },
    setup() {
      this.$store.dispatch("verifyToken");
      this.loadRelatorio();
      this.loadUsuarios();
    },
  },
  mounted() {
    this.setup();
  },
};
</script>

<style scoped>
.totais {
  padding: 20px 0px;
}

.totais ul {
  list-style: none;
}

.totais li {
  font-weight: bold;
  padding-top: 5px;
}
</style>